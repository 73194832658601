import React from "react";
import { FaArrowRight } from "react-icons/fa";

const Subscribe = ({ theme }) => (
  <form
    style={{ padding: "3px", textAlign: "center" }}
    action="https://tinyletter.com/javascriptfacil"
    method="post"
    target="popupwindow"
    onSubmit={() => {
      window.open(
        "https://tinyletter.com/javascriptfacil",
        "popupwindow",
        "scrollbars=yes,width=800,height=600"
      );
      return true;
    }}
  >
    <style jsx>
      {`
        button {
          background: ${theme.background.color.brand};
          border: 0;
          border-radius: 50%;
          font-size: ${theme.font.size.s};
          padding: ${theme.space.s} ${theme.space.s};
          cursor: pointer;
          width: ${theme.space.md};
          height: ${theme.space.md};
          display: inline-block;
          fill: ${theme.color.special.attention};
          stroke: ${theme.color.special.attention};
          color: #fff;
          stroke-width: 40;
          stroke-linecap: round;
          transition: all 0.5s;
          transform: translateX(-50%);
        }

        #tlemail {
          font-size: ${theme.font.size.m};
          width: 250px;
        }
      `}
    </style>
    <div>
      <h2>Suscríbete a nuestro boletín</h2>
      <p>Mantente al tanto de nuestras últimas noticias, cursos interactivos y artículos.</p>
      <input type="text" placeholder="tu@correo.com" name="email" id="tlemail" />
      <input type="hidden" value="" name="embed" />
      <button type="submit">
        <FaArrowRight className="arrow" />
      </button>
    </div>
  </form>
);

export default Subscribe;
